import effector, { createStore, createApi } from 'effector';
import * as storeReact from "effector-react";
import { redirect } from 'react-router-dom';
import { AxiosInstance as HttpInstance, AxiosResponse as HttpResponse } from "axios";
import * as Persistor from './persistor';
import * as Auth from './auth';

export interface City {
    _id: string;
    name: string;
    user: string;
    tasks: Task[];
    __v: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface Task {
    _id: string;
    year: number;
    month: "01" | "02" | "03" | "04" | "05" | "06" | "07" | "08" | "09" | "10" | "11" | "12";
    city: City;
    user: string;
    primary_network: number;
    territorial_services_network: number;
    book_visits_recipes: number;
    home_autonomy: number;
    relational_support: number;
    administrative_practices: number;
    social_telephony: number;
    social_accompaniments: number;
    sanitary_accompaniments: number;
    aass_administrative_connection: number;
    reports_miscellaneous: number;
    total_patients: number;
    discharged_patients: number;
    __v: number;
    active: boolean;
    createdAt?: Date;
    updatedAt?: Date;
};

export interface SessionState {
    account: any;
    isAuthenticated: boolean;
    cities: City[];
    tasks: Task[];
};

export const defaultSessionState: SessionState = {
    account: {},
    isAuthenticated: false,
    cities: [],
    tasks: [],
};

class SharedStore {
    private persistor: HttpInstance;
    private auth: HttpInstance;
    private SessionStore: effector.Store<SessionState>;
    private sessionApi: {
        setInitApp: effector.Event<{
            account: {};
            isAuthenticated: boolean;
            cities?: City[];
            tasks?: Task[];
        }>;
        setCities: effector.Event<City[]>;
        setTasks: effector.Event<Task[]>;
        setCity: effector.Event<City>;
        setTask: effector.Event<Task>;

    };
    public constructor(
        initState: SessionState
    ) {
        this.persistor = Persistor.Instance();
        this.auth = Auth.Instance();
        this.SessionStore = createStore(initState);
        this.sessionApi = createApi(this.SessionStore, {
            setInitApp: (
                state: any,
                init: {
                    account: {};
                    isAuthenticated: boolean;
                    cities?: City[];
                    tasks?: Task[];
                }
            ) => (state = { ...state, ...init }),
            setCities: (state: any, cities: City[]) => (state = { ...state, cities: cities }),
            setTasks: (state: any, tasks: Task[]) => (state = { ...state, tasks: tasks }),
            setCity: (state: any, city: City) => {
                const i = state.cities.findIndex((c: City) => {
                    return c._id === city._id
                })
                if (i >= 0) state.cities[i] = city
                else state.cities.push(city)
                return (state = { ...state })
            },
            setTask: (state: any, task: Task) => {
                const i = state.tasks.findIndex((t: Task) => {
                    return t._id === task._id
                })
                if (i >= 0) state.tasks[i] = task
                else state.tasks.push(task)
                return (state = { ...state })
            },
        });

        this.SessionStore.watch(this.sessionApi.setCity, (state, city) => {
            console.log('setCity', city, state.cities)
        })

        this.SessionStore.watch(this.sessionApi.setTask, (state, task) => {
            console.log('setTask', task, state.tasks)
        })

        this.persistor
            .get("/city")
            .then((response: HttpResponse) => {
                this.sessionApi.setCities(response.data.cities);
            })
            .catch((error: Error) => {
                console.error(error);
            });

        this.persistor
            .get("/task")
            .then((response: HttpResponse) => {
                this.sessionApi.setTasks(response.data.tasks);
            })
            .catch((error: Error) => {
                console.error(error);
            });
    };

    public getSessionStore() {
        return this.SessionStore;
    }

    public getSessionApi() {
        return this.sessionApi;
    }

    public login() {
        this.auth
            .get("/checkAuthentication")
            .then((response: HttpResponse) => {
                if (!response.data.isAuthenticated) {
                    this.sessionApi.setInitApp({ account: {}, isAuthenticated: false });
                    window.location.pathname = `/api/v1/auth/login`;
                } else {
                    const profile = response.data.profile;
                    this.sessionApi.setInitApp({ account: profile, isAuthenticated: true });
                    redirect("/home")
                }
            })
            .catch((error: Error) => {
                console.error(error);
            });
    }

    public logout() {
        window.location.pathname = `/api/v1/auth/logout`;
    }

    public setCity(city: City) {
        this.persistor
            .put(`/city/${city._id}`, { name: city.name, user: city.user } as City)
            .then((response: HttpResponse) => {
                this.sessionApi.setCity(response.data.city)
            })
            .catch((error: Error) => {
                console.error(error);
            });
    }

    public newCity(city: City) {
        this.persistor
            .post(`/city`, { name: city.name, user: city.user } as City)
            .then((response: HttpResponse) => {
                this.sessionApi.setCity(response.data.city)
            })
            .catch((error: Error) => {
                console.error(error);
            });
    }

    public setTask(task: Task) {
        this.persistor
            .put(`/task/${task._id}`, {
                year: task.year,
                month: task.month,
                city: task.city,
                user: task.user,
                primary_network: task.primary_network,
                territorial_services_network: task.territorial_services_network,
                book_visits_recipes: task.book_visits_recipes,
                home_autonomy: task.home_autonomy,
                relational_support: task.relational_support,
                administrative_practices: task.administrative_practices,
                social_telephony: task.social_telephony,
                social_accompaniments: task.social_accompaniments,
                sanitary_accompaniments: task.sanitary_accompaniments,
                aass_administrative_connection: task.aass_administrative_connection,
                reports_miscellaneous: task.reports_miscellaneous,
                total_patients: task.total_patients,
                discharged_patients: task.discharged_patients,
                active: task.active
            } as Task)
            .then((response: HttpResponse) => {
                this.sessionApi.setTask(response.data.task)
            })
            .catch((error: Error) => {
                console.error(error);
            });
    }


    public newTask(task: Task) {
        this.persistor
            .post(`/task`, {
                year: task.year,
                month: task.month,
                city: task.city,
                user: task.user,
                active: task.active
            } as Task)
            .then((response: HttpResponse) => {
                this.sessionApi.setTask(response.data.task)
            })
            .catch((error: Error) => {
                console.error(error);
            });
    }

}

const sharedStore = new SharedStore(defaultSessionState);

export {
    sharedStore,
    storeReact
}