/**
 * react-i18next is a powerful internationalization framework for
 * React/ReactNative which is based on i18next.
 * Learn more: https://react.i18next.com
 */

// External imports
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Local imports
import resourcesEn from './resources.en.json';
import resourcesEs from './resources.es.json';
import resourcesIt from './resources.it.json';

// Init the i18next module with the resource files
const initI18n = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translations: { ...resourcesEn },
        },
        es: {
          translations: { ...resourcesEs },
        },
        it: {
          translations: { ...resourcesIt },
        },
      },
      lng: "it",
      fallbackLng: 'it',
      debug: true,
      ns: ['translations'],
      defaultNS: 'translations',
      keySeparator: ".",
      interpolation: {
        escapeValue: false,
      },
    });
};

export default initI18n;
