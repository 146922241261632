import React from "react";
import { Box, FormControl, TextField, DialogActions, DialogContent, DialogTitle, Button, InputAdornment } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-pro';
import { AddLocationAltTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { sharedStore, storeReact, City } from '../../../../stores';
const $session = sharedStore.getSessionStore();

interface PropCityForm {
    selectedId: GridRowId | undefined;
    handleClose: any
}

function CityForm({ selectedId, handleClose }: PropCityForm) {
    const { t } = useTranslation();
    const { account } = storeReact.useStore($session);
    const city: City | undefined = storeReact.useStoreMap({
        store: $session,
        keys: [selectedId],
        fn: (state, [cityId]) => state.cities.find(({ _id }) => _id === cityId),
    })
    const internalClose = (event:any) => {
        event.preventDefault();
        sharedStore.setCity({
            ...city,
            name: event.target.name.value,
            user: account?.preferred_username,
        } as City);
        handleClose();
    };

    if (!city) return <div></div>
    return (
        <Box
            component="form"
            onSubmit={internalClose}
        >
            <DialogTitle>{t('layout.forms.city.title')}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin='normal'>
                    <TextField
                        label={t('city.name')}
                        variant="filled"
                        defaultValue={city.name}
                        id="name"
                        name="name" 
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><AddLocationAltTwoTone /></InputAdornment>,
                          }}/>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="error">{t('layout.forms.city.cancel')}</Button>
                <Button type="submit" variant="outlined" color="success">{t('layout.forms.city.save')}</Button>
            </DialogActions>
        </Box>
    )
}

export default CityForm;