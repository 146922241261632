import React from 'react';
import _ from 'lodash';
import {
  DataGridPro, GridRowId, GridColDef, GridRowsProp, GridActionsCellItem, GridColumnHeaderParams, GridRenderCellParams,
  GridToolbarContainer, GridToolbarExport
} from '@mui/x-data-grid-pro';
import { Dialog, IconButton } from '@mui/material';
import { AddCircleTwoTone, EditNoteTwoTone, CheckTwoTone, CloseTwoTone, SecurityTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Details from './forms/details'
import TaskNewForm from './forms/new';
import TaskEditForm from './forms/edit';

import { Task, sharedStore, storeReact } from '../../../stores';
const $session = sharedStore.getSessionStore();

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function Tasks() {
  const { t } = useTranslation();
  const { tasks } = storeReact.useStore($session);
  const [taskId, setTaskId] = React.useState<GridRowId>();
  const [open, setOpen] = React.useState(false);

  const editTask = React.useCallback(
    (id: GridRowId) => () => {
      console.log(id)
      setTaskId(id)
      setOpen(true)
    }, [],
  );

  const activeTask = React.useCallback(
    (id: GridRowId) => () => {
      // @ts-ignore
      const row: Task = _.find(tasks, { _id: id });
      if (row) sharedStore.setTask({
        ...row,
        active: !row.active
      } as Task);
    }, [tasks],
  );

  const newTask = React.useCallback(
    () => () => {
      setTaskId(undefined)
      setOpen(true)
    }, [],
  );

  const handleClose = () => {
    setTaskId(undefined)
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      //@ts-ignore
      getActions: (params:any) => [
          <GridActionsCellItem
            color="primary"
            icon={<EditNoteTwoTone fontSize="large" />}
            label="Delete"
            onClick={editTask(params.id)}
          />,
          <GridActionsCellItem
            color="primary"
            icon={<SecurityTwoTone fontSize="large" />}
            label="Active"
            onClick={activeTask(params.id)}
          />
      ],
      renderHeader: (params: GridColumnHeaderParams) => (
        <IconButton color="success" aria-label="add city" onClick={newTask()}>
          <AddCircleTwoTone fontSize="large" />
        </IconButton>

      ),
    },
    { field: '_id', headerName: 'ID', flex: 1, hideable: true, resizable: true },
    { field: 'city', headerName: t('task.city'), flex: 1, resizable: true },
    { field: 'user', headerName: t('task.user'), flex: 1, resizable: true },
    { field: 'year', headerName: t('task.year'), flex: 1, resizable: true },
    { field: 'month', headerName: t('task.month'), flex: 1, resizable: true, renderCell: (params: GridRenderCellParams) => t(`task.monthList.${params.value}`) },
    { field: 'primary_network', headerName: t('task.primary_network'), flex: 1, resizable: true },
    { field: 'territorial_services_network', headerName: t('task.territorial_services_network'), flex: 1, resizable: true },
    { field: 'book_visits_recipes', headerName: t('task.book_visits_recipes'), flex: 1, resizable: true },
    { field: 'home_autonomy', headerName: t('task.home_autonomy'), flex: 1, resizable: true },
    { field: 'relational_support', headerName: t('task.relational_support'), flex: 1, resizable: true },
    { field: 'administrative_practices', headerName: t('task.administrative_practices'), flex: 1, resizable: true },
    { field: 'social_telephony', headerName: t('task.social_telephony'), flex: 1, resizable: true },
    { field: 'social_accompaniments', headerName: t('task.social_accompaniments'), flex: 1, resizable: true },
    { field: 'sanitary_accompaniments', headerName: t('task.sanitary_accompaniments'), flex: 1, resizable: true },
    { field: 'aass_administrative_connection', headerName: t('task.aass_administrative_connection'), flex: 1, resizable: true },
    { field: 'reports_miscellaneous', headerName: t('task.reports_miscellaneous'), flex: 1, resizable: true },
    { field: '__v', headerName: t('task.__v'), flex: 1, resizable: true },
    {
      field: 'active', headerName: t('task.active'), flex: 1, resizable: true,
      renderCell: ({ value }) => value ? (<CheckTwoTone color="success" fontSize="large" />) : (<CloseTwoTone color="error" fontSize="large" />)
    },
    { field: 'createdAt', headerName: t('task.createdAt'), flex: 1, resizable: true },
    { field: 'updatedAt', headerName: t('task.updatedAt'), flex: 1, resizable: true },
  ];
  const rows: GridRowsProp = _(tasks).map((c) => {
    return {
      _id: c._id, year: c.year, month: c.month, city: c.city?.name, user: c.user,
      primary_network: c.primary_network,
      territorial_services_network: c.territorial_services_network,
      book_visits_recipes: c.book_visits_recipes,
      home_autonomy: c.home_autonomy,
      relational_support: c.relational_support,
      administrative_practices: c.administrative_practices,
      social_telephony: c.social_telephony,
      social_accompaniments: c.social_accompaniments,
      sanitary_accompaniments: c.sanitary_accompaniments,
      aass_administrative_connection: c.aass_administrative_connection,
      reports_miscellaneous: c.reports_miscellaneous,
      __v: c.__v, active: c.active, createdAt: c.createdAt, updatedAt: c.updatedAt
    }
  }).value();

  const getDetailPanelContent = React.useCallback(({ row }: any) => <Details row={row} />, []);
  const getDetailPanelHeight = React.useCallback(() => 400, []);
  return (
    <>
      <div style={{ width: '100%' }}>
        <DataGridPro density="comfortable" getRowId={(c) => c._id}
          pageSize={5} rowsPerPageOptions={[5, 10, 20, 50]} pagination autoHeight
          columns={columns}
          rows={rows}
          rowThreshold={0}
          columnVisibilityModel={{
            _id: false,
            user: false,
            createdAt: false,
            updatedAt: false,
            __v: false,
            primary_network: false,
            territorial_services_network: false,
            book_visits_recipes: false,
            home_autonomy: false,
            relational_support: false,
            administrative_practices: false,
            social_telephony: false,
            social_accompaniments: false,
            sanitary_accompaniments: false,
            aass_administrative_connection: false,
            reports_miscellaneous: false,
          }}
          // @ts-ignore
          slots={{
            toolbar: CustomToolbar,
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent} />
      </div>
      <Dialog open={open} onClose={handleClose} fullScreen>
        {taskId ? (
          <TaskEditForm selectedId={taskId} handleClose={handleClose} />
        ) : (
          <TaskNewForm handleClose={handleClose} />
        )}
      </Dialog>
    </>
  )
}

export default Tasks;