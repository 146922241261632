import React from "react";
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { sharedStore, storeReact } from '../../stores';
const $session = sharedStore.getSessionStore();

function Details() {
  const { t } = useTranslation();
  const { tasks } = storeReact.useStore($session);

  const series = _(tasks)
    .filter((t) => {
      return t.year === (new Date().getFullYear())
    })
    .groupBy((b) => { return b.city.name })
    .map((city, id) => ({
      name: id,
      data: _.map(city, (p) => {
        return p.primary_network +
          p.book_visits_recipes +
          p.territorial_services_network +
          p.home_autonomy +
          p.relational_support +
          p.administrative_practices +
          p.social_telephony +
          p.social_accompaniments +
          p.sanitary_accompaniments +
          p.aass_administrative_connection +
          p.reports_miscellaneous
      })
    }))
    .value();

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'heatmap',
    },
    plotOptions: {
      heatmap: {
        radius: 10,
        enableShades: false,
        colorScale: {
          ranges: [{
            from: 0,
            to: 100,
            color: '#15b968'
          },
          {
            from: 101,
            to: 200,
            color: '#e8821b'
          },
          {
            from: 201,
            to: Infinity,
            color: '#e81c1b'
          },
          ],
        },

      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '15px',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        colors: ['#fff']
      },
    },
    xaxis: {
      categories: _.uniqBy(tasks, (o) => { return `${o.year}-${t(`task.monthList.${o.month}`)}` })
        .filter((t) => {
          return t.year === (new Date().getFullYear())
        })
        .map(b => `${b.year}-${t(`task.monthList.${b.month}`)}`),
    },
    title: {
      text: t(`layout.pages.title.dashboard.01`, { period: new Date().getFullYear()}),
      align: 'center'
    },
  }

  const series2 = _(tasks)
    .filter((t) => {
      return t.year === (new Date().getFullYear())
    })
    .groupBy((b) => { return b.city.name })
    .map((city, id) => ({
      name: id,
      data: [
        _.sumBy(city, 'primary_network'),
        _.sumBy(city, 'territorial_services_network'),
        _.sumBy(city, 'book_visits_recipes'),
        _.sumBy(city, 'home_autonomy'),
        _.sumBy(city, 'relational_support'),
        _.sumBy(city, 'administrative_practices'),
        _.sumBy(city, 'social_telephony'),
        _.sumBy(city, 'social_accompaniments'),
        _.sumBy(city, 'sanitary_accompaniments'),
        _.sumBy(city, 'aass_administrative_connection'),
        _.sumBy(city, 'reports_miscellaneous')
      ]
    }))
    .value();

  const options2: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'top',
          offsetY: 40
        },
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '15px',
              fontWeight: 900
            }
          }
        }
      },
    },
    title: {
      text: t(`layout.pages.title.dashboard.02`, { period: new Date().getFullYear()}),
      align: 'center'
    },
    xaxis: {
      categories: [
        t('task.primary_network'),
        t('task.book_visits_recipes'),
        t('task.territorial_services_network'),
        t('task.home_autonomy'),
        t('task.relational_support'),
        t('task.administrative_practices'),
        t('task.social_telephony'),
        t('task.social_accompaniments'),
        t('task.sanitary_accompaniments'),
        t('task.aass_administrative_connection'),
        t('task.reports_miscellaneous')
      ]
    }
  }

  return (
    <>
      <Grid container spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={12} sm={12} md={6}>
          <div id="chart">
            <ReactApexChart options={options} series={series} type="heatmap" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div id="chart">
            <ReactApexChart options={options2} series={series2} type="bar" />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Details;
