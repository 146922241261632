// External imports
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { styled } from '@mui/material';

import { sharedStore, storeReact } from '../stores';
import Header from '../layout/Header'
import HomePage from '../pages/Home';
import RegistriesPage from '../pages/Registries';
import DashboardPage from '../pages/Dashboard';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey("57f53045889ef1d2faf8eeceee7583b6T1JERVI6Mzk0MTQsRVhQSVJZPTE2Nzg1MzMwODEwMDAsS0VZVkVSU0lPTj0x");

const $session = sharedStore.getSessionStore();

// Component definition
function App() {
  const { isAuthenticated } =
    storeReact.useStore($session);
  React.useEffect(() => {
    sharedStore.login();
  }, [])
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  if (!isAuthenticated) return null
  return (
    <>
      <Header />
      <Offset />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/registries" element={<RegistriesPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
    </>
  );
}

// Default export
export default React.memo(App);
