import React from 'react';
import _ from 'lodash';
import { GridRowId } from '@mui/x-data-grid-pro';
import {
  Grid, Typography, Dialog, Button, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, Fab,
  DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { deepOrange, green } from '@mui/material/colors';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import PlaylistAddCheckCircleTwoTone from '@mui/icons-material/PlaylistAddCheckCircleTwoTone';
import PlaylistAddCircleTwoTone from '@mui/icons-material/PlaylistAddCircleTwoTone';
import { useTranslation } from 'react-i18next';

import TaskNewForm from './forms/new';
import TaskEditForm from './forms/edit';

import { Task, sharedStore, storeReact } from '../../stores';
const $session = sharedStore.getSessionStore();

interface PropCloseTask {
  selectedId: GridRowId | undefined;
  handleClose: any;
}

function CloseDialog({ selectedId, handleClose }: PropCloseTask) {
  const { t } = useTranslation();
  const task: Task | undefined = storeReact.useStoreMap({
    store: $session,
    keys: [selectedId],
    fn: (state, [taskId]) => state.tasks.find(({ _id }) => _id === taskId),
})
  const internalClose = (event:any) => {
    event.preventDefault();
    sharedStore.setTask({
        ...task,
        active: false
    } as Task);
    handleClose();
};
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {t(`layout.forms.task.dialog.title`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {t(`layout.forms.task.dialog.messge`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="error">{t(`layout.forms.task.cancel`)}</Button>
        <Button variant="outlined" onClick={internalClose} color="success">{t(`layout.forms.task.save`)}</Button>
      </DialogActions>
    </>
  )
}

function Tasks() {
  const { t } = useTranslation();
  const { tasks } = storeReact.useStore($session);
  const [taskId, setTaskId] = React.useState<GridRowId>();
  const [close, setClose] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const editTask = React.useCallback(
    (id: GridRowId) => () => {
      console.log(id)
      setTaskId(id)
      setClose(false)
      setOpen(true)
    }, [],
  );

  const closeTask = React.useCallback(
    (id: GridRowId) => () => {
      console.log(id)
      setTaskId(id)
      setClose(true)
      setOpen(true)
    }, [],
  );

  const newTask = React.useCallback(
    () => () => {
      setTaskId(undefined)
      setClose(false)
      setOpen(true)
    }, [],
  );

  const handleClose = () => {
    setTaskId(undefined)
    setClose(false)
    setOpen(false);
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2, textAlign: 'center' }} variant="h6" component="div">
          {t(`layout.pages.title.home`)}
          </Typography>
          <List>
            {
              _.filter(tasks, (t) => {
                return t.active === true
              }).map((o, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemAvatar>
                      <Avatar variant="rounded" sx={{ bgcolor: deepOrange[500] }}
                        onClick={closeTask(o._id)}>
                        <IconButton aria-label="delete">
                          <PlaylistAddCheckCircleTwoTone fontSize="large" />
                        </IconButton>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemAvatar>
                      <Avatar variant="rounded" sx={{ bgcolor: green[500] }}
                        onClick={editTask(o._id)}>
                        <IconButton aria-label="delete">
                          <PlaylistAddCircleTwoTone fontSize="large" />
                        </IconButton>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={o.city.name}
                      secondary={`${t(`task.monthList.${o.month}`)} ${o.year}`}
                    />
                  </ListItem>
                )
              })
            }
            <Fab color="success" aria-label="add"
              onClick={newTask()}
              sx={{
                position: 'absolute',
                bottom: 16,
                right: 16,
              }}>
              <AddCircleTwoToneIcon />
            </Fab>
          </List>
        </Grid>
      </div>
      <Dialog open={open} onClose={handleClose} fullScreen={!close}>
        {close ? <CloseDialog selectedId={taskId} handleClose={handleClose} />
          : taskId ? (
            <TaskEditForm selectedId={taskId} handleClose={handleClose} />
          ) : (
            <TaskNewForm handleClose={handleClose} />
          )}
      </Dialog>
    </>
  )
}

export default Tasks;