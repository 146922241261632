import React from 'react';
import {
    DataGridPro, GridRowId, GridColDef, GridRowsProp, GridActionsCellItem, GridColumnHeaderParams,
    GridToolbarContainer, GridToolbarExport
} from '@mui/x-data-grid-pro';
import { Dialog, IconButton } from '@mui/material';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone';
import { useTranslation } from 'react-i18next';

import CityEditForm from './forms/edit';
import CityNewForm from './forms/new';

import { sharedStore, storeReact } from '../../../stores';
const $session = sharedStore.getSessionStore();

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

function Cities() {
    const { t } = useTranslation();
    const { cities } = storeReact.useStore($session);
    const [cityId, setCityId] = React.useState<GridRowId>();
    const [open, setOpen] = React.useState(false);

    const editCity = React.useCallback(
        (id: GridRowId) => () => {
            console.log(id)
            setCityId(id)
            setOpen(true)
        }, [],
    );

    const newCity = React.useCallback(
        () => () => {
            setCityId(undefined)
            setOpen(true)
        }, [],
    );

    const handleClose = () => {
        setCityId(undefined)
        setOpen(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            //@ts-ignore
            getActions: (params:any) => [
                <GridActionsCellItem
                    color="primary"
                    icon={<EditNoteTwoToneIcon fontSize="large" />}
                    label="Delete"
                    onClick={editCity(params.id)}
                />
            ],
            renderHeader: (params: GridColumnHeaderParams) => (
                <IconButton color="success" aria-label="add city" onClick={newCity()}>
                    <AddCircleTwoToneIcon fontSize="large" />
                </IconButton>
            ),
        },
        { field: '_id', headerName: 'ID', flex: 1, hideable: true, resizable: true },
        { field: 'name', headerName: t('city.name'), flex: 1, resizable: true },
        { field: 'user', headerName: t('city.user'), flex: 1, resizable: true },
        { field: 'tasks', headerName: t('city.tasks'), flex: 1, resizable: true },
        { field: '__v', headerName: t('city.__v'), flex: 1, resizable: true },
        { field: 'createdAt', headerName: t('city.createdAt'), flex: 1, resizable: true },
        { field: 'updatedAt', headerName: t('city.updatedAt'), flex: 1, resizable: true },
    ];
    const rows: GridRowsProp = cities.map((c) => {
        return { _id: c._id, name: c.name, user: c.user, tasks: c.tasks.length, __v: c.__v, createdAt: c.createdAt, updatedAt: c.updatedAt }
    })
    return (
        <>
            <div style={{ width: '100%' }}>
                <DataGridPro density="comfortable" getRowId={(c) => c._id}
                    pageSize={5} rowsPerPageOptions={[5, 10, 20, 50]} pagination autoHeight
                    columns={columns}
                    rows={rows}
                    rowThreshold={0}
                    // @ts-ignore
                    slots={{
                        toolbar: CustomToolbar,
                      }}
                    columnVisibilityModel={{
                        _id: false,
                        user: false,
                        createdAt: false,
                        updatedAt: false,
                        tasks: false
                    }}/>
            </div>
            <Dialog open={open} onClose={handleClose} fullScreen>
                {cityId ? (
                    <CityEditForm selectedId={cityId} handleClose={handleClose} />
                ) : (
                    <CityNewForm handleClose={handleClose} />
                )}
            </Dialog>
        </>
    )
}

export default Cities;