import axios, {AxiosInstance} from "axios";

/* 
    import {Connection} from 'sharedb/lib/client';
    import ReconnectingWebSocket from 'reconnecting-websocket'; 
*/

const Instance = (
    timeout: number = 5000,
    baseURL: string = "/api/v1/store"
): AxiosInstance => {
    const instance = axios.create({
        baseURL: baseURL,
        timeout: timeout,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        },
    });

    instance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return instance;
};

export {Instance};