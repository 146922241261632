import React from "react";
import { Box, FormControl, TextField, DialogActions, DialogContent, DialogTitle, Button, InputLabel, Select, MenuItem,InputAdornment } from '@mui/material';
import { AddLocationAltTwoTone, CalendarMonthTwoTone, AccessibleForwardTwoTone, AccessibilityNewTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { sharedStore, storeReact, Task } from '../../../../stores';
const $session = sharedStore.getSessionStore();

interface PropTaskForm {
    handleClose: any
}

function TaskForm({ handleClose }: PropTaskForm) {
    const { t } = useTranslation();
    const { cities, account } = storeReact.useStore($session);

    const internalClose = (event:any) => {
        event.preventDefault();
        sharedStore.newTask({
            year: event.target.year.value,
            month: event.target.month.value,
            user: account?.preferred_username,
            city: event.target.city.value,
            total_patients: event.target.total_patients.value,
            discharged_patients: event.target.discharged_patients.value,
            active: true
        } as Task);
        handleClose();
    };

    return (
        <Box
            component="form"
            onSubmit={internalClose}
        >
            <DialogTitle>{t('layout.forms.task.title')}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin='dense'>
                    <TextField
                        label={t('task.year')}
                        type="number"
                        variant="outlined"
                        defaultValue=""
                        id="year"
                        name="year" 
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><CalendarMonthTwoTone /></InputAdornment>,
                          }}/>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel id="monthLabel">{t('task.month')}</InputLabel>
                    <Select
                        label={t('task.month')}
                        labelId="monthLabel"
                        variant="outlined"
                        defaultValue={""}
                        id="month"
                        name="month"
                        startAdornment={
                            <InputAdornment position="start">
                              <CalendarMonthTwoTone />
                            </InputAdornment>
                          }
                    >
                        {
                            ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((c,i) => {
                                return <MenuItem value={c} key={i}>{t(`task.monthList.${c}`)}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel id="cityLabel">{t('task.city')}</InputLabel>
                    <Select
                        label={t('task.city')}
                        labelId="cityLabel"
                        variant="outlined"
                        defaultValue={""}
                        id="city"
                        name="city"
                        startAdornment={
                            <InputAdornment position="start">
                              <AddLocationAltTwoTone />
                            </InputAdornment>
                          }
                    >
                        {
                            cities.map((c,i) => {
                                return <MenuItem value={c._id} key={i}>{c.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <TextField
                        label={t('task.total_patients')}
                        type="number"
                        variant="outlined"
                        defaultValue={""}
                        id="total_patients"
                        name="total_patients" 
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><AccessibleForwardTwoTone /></InputAdornment>,
                          }}
                        />
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <TextField
                        label={t('task.discharged_patients')}
                        type="number"
                        variant="outlined"
                        defaultValue={""}
                        id="discharged_patients"
                        name="discharged_patients" 
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><AccessibilityNewTwoTone /></InputAdornment>,
                          }}
                        />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="error">{t('layout.forms.task.cancel')}</Button>
                <Button type="submit" variant="outlined" color="success">{t('layout.forms.task.save')}</Button>
            </DialogActions>
        </Box>
    )
}

export default TaskForm;