import React from "react";
import { Grid, Box, FormControl, TextField, DialogContent, DialogTitle, Button, InputAdornment } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-pro';
import { RingVolumeTwoTone, ArrowCircleUpTwoTone, ArrowCircleDownTwoTone, AccessibleForwardTwoTone, AccessibilityNewTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { sharedStore, storeReact, Task } from '../../../stores';
const $session = sharedStore.getSessionStore();

interface PropTaskForm {
    selectedId: GridRowId | undefined;
    handleClose: any
}

function TaskForm({ selectedId, handleClose }: PropTaskForm) {
    const { t } = useTranslation();
    const formTask = React.useRef<HTMLInputElement>(null);
    const task: Task | undefined = storeReact.useStoreMap({
        store: $session,
        keys: [selectedId],
        fn: (state, [taskId]) => state.tasks.find(({ _id }) => _id === taskId),
    })

    const internalClose = (event:any) => {
        event.preventDefault();
        sharedStore.setTask({
            ...task,
            primary_network: event.target.primary_network.value,
            territorial_services_network: event.target.territorial_services_network.value,
            book_visits_recipes: event.target.book_visits_recipes.value,
            home_autonomy: event.target.home_autonomy.value,
            relational_support: event.target.relational_support.value,
            administrative_practices: event.target.administrative_practices.value,
            social_telephony: event.target.social_telephony.value,
            social_accompaniments: event.target.social_accompaniments.value,
            sanitary_accompaniments: event.target.sanitary_accompaniments.value,
            aass_administrative_connection: event.target.aass_administrative_connection.value,
            reports_miscellaneous: event.target.reports_miscellaneous.value,
            total_patients: event.target.total_patients.value,
            discharged_patients: event.target.discharged_patients.value,
        } as Task);
        handleClose();
    };

    const increment = React.useCallback(
        (field: String) => () => {
            //@ts-ignore
            if (formTask.current) formTask.current[field].value++;
        }, [],
    );

    const decrement = React.useCallback(
        (field: String) => () => {
            //@ts-ignore
            if (formTask.current) formTask.current[field].value--;
        }, [],
    );
    const fields = [
        "primary_network",
        "territorial_services_network",
        "book_visits_recipes",
        "home_autonomy",
        "relational_support",
        "administrative_practices",
        "social_telephony",
        "social_accompaniments",
        "sanitary_accompaniments",
        "aass_administrative_connection",
        "reports_miscellaneous",
    ]
    if (!task) return <div></div>
    return (
        <Box
            component="form"
            onSubmit={internalClose}
            ref={formTask}
        >
            <DialogTitle>
                {`${task.city.name} [${t(`task.monthList.${task.month}`)} ${task.year}]`}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={6} sm={6} md={3}>
                        <Button variant="outlined" onClick={handleClose} color="error">{t('layout.forms.task.cancel')}</Button>
                        <Button type="submit" variant="outlined" color="success">{t('layout.forms.task.save')}</Button>
                    </Grid>
                    {
                        fields.map((f, i) => {
                            return (
                                <Grid item xs={6} sm={6} md={3} key={i}>
                                    <FormControl fullWidth margin='normal' size="small">
                                        <TextField
                                            label={t(`task.${f}`)}
                                            type="number"
                                            //@ts-ignore
                                            min="0"
                                            step="1"
                                            variant="outlined"
                                            //@ts-ignore
                                            defaultValue={task[f]}
                                            id={f}
                                            name={f}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><RingVolumeTwoTone /></InputAdornment>,
                                                endAdornment: (<InputAdornment position="end">
                                                    <ArrowCircleUpTwoTone fontSize="large" color="success" onClick={increment(f)} />
                                                    <ArrowCircleDownTwoTone fontSize="large" color="error" onClick={decrement(f)} />
                                                </InputAdornment>)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={6} sm={6} md={3}>
                        <FormControl fullWidth margin='normal'>
                            <TextField
                                label={t('task.total_patients')}
                                type="number"
                                variant="outlined"
                                defaultValue={""}
                                id="total_patients"
                                name="total_patients"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><AccessibleForwardTwoTone /></InputAdornment>,
                                    endAdornment: (<InputAdornment position="end">
                                        <ArrowCircleUpTwoTone fontSize="large" color="success" onClick={increment("total_patients")} />
                                        <ArrowCircleDownTwoTone fontSize="large" color="error" onClick={decrement("total_patients")} />
                                    </InputAdornment>)
                                }}
                            />
                        </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                        <FormControl fullWidth margin='normal'>
                            <TextField
                                label={t('task.discharged_patients')}
                                type="number"
                                variant="outlined"
                                defaultValue={""}
                                id="discharged_patients"
                                name="discharged_patients"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><AccessibilityNewTwoTone /></InputAdornment>,
                                    endAdornment: (<InputAdornment position="end">
                                        <ArrowCircleUpTwoTone fontSize="large" color="success" onClick={increment("discharged_patients")} />
                                        <ArrowCircleDownTwoTone fontSize="large" color="error" onClick={decrement("discharged_patients")} />
                                    </InputAdornment>)
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
        </Box>
    )
}

export default TaskForm;