// Local imports
import React from 'react';
import Dashboard from '../../components/Dashboard';

// Component definition
function DashboardPage() {
  return (
    <Dashboard
    //@ts-ignore
      box={{
        sx: {
          background: 'rgb(0, 30, 60)',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    />
  );
}

// Default export
export default DashboardPage;
