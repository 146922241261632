import React from "react";
import { Box, Paper, Card, CardContent, Typography, Grid, Divider } from '@mui/material';
import { AccountCircleTwoTone, AddLocationAltTwoTone, CalendarMonthTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function Details({ row }: any) {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: '95%',
                    height: 128,
                },
            }}
        >
            <Paper elevation={0} variant="outlined">
                <Card variant="outlined">
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={2}>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    <CalendarMonthTwoTone />{`${t(`task.monthList.${row.month}`)} ${row.year}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Typography variant="h5" component="div">
                                    <AddLocationAltTwoTone />{row.city}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    <AccountCircleTwoTone />{row.user}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Typography variant="body2">
                            <Grid container>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.primary_network')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.primary_network}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.territorial_services_network')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.territorial_services_network}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.book_visits_recipes')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.book_visits_recipes}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.home_autonomy')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.home_autonomy}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.relational_support')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.relational_support}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.administrative_practices')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.administrative_practices}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.social_telephony')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.social_telephony}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.social_accompaniments')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.social_accompaniments}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.sanitary_accompaniments')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.sanitary_accompaniments}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.aass_administrative_connection')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.aass_administrative_connection}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {t('task.reports_miscellaneous')}
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    {row.reports_miscellaneous}
                                </Grid>
                            </Grid>
                        </Typography>
                    </CardContent>
                </Card>
            </Paper>
        </Box>
    )
}

export default Details;